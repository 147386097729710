import React from "react"
import Layout from "../components/Layout/Layout"
import Pricing from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const DevelopersPage = function () {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "developer.png" }) {
        childImageSharp {
          fixed(width: 380, height: 330) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout header={true} footer={true}>
      <SEO title="Home" />
      <section className="section">
      <div className="container">
          <div className="columns">
            <div className="column content is-two-thirds">
              <h1>For Developers</h1>
              <div className="tabs">
                <ul>
                  <li className="is-active">
                    <a>Get Started</a>
                  </li>
                  <li>
                    <a>API Access</a>
                  </li>
                  <li>
                    <a>Forum</a>
                  </li>
                </ul>
              </div>
              <p>
                Hello. Are you looking to integrate your platform with us? Well,
                you've come to the right place.
              </p>
              <p>
                We want to make it as easy as possible to allow our partners to
                integrate with our platform.
              </p>
              <h2>Get Started</h2>
              <ol>
                <li>Sign up</li>
                <li>Grab an API Key</li>
                <li>Hit our API (nice)</li>
              </ol>
            </div>
            <div className="column is-hidden-mobile">
              <Img fixed={data.file.childImageSharp.fixed} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DevelopersPage
